<template>
	<div>
		<template v-if="!loading.overall">
			<v-container>
				<!-- Header -->
				<SettingsGear :showIcon="true" />
				<div style="height: 15vh">
					<div
						style="
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: center;
              margin-top: 2vh;
            "
					>
						<img
							src="@/assets/logos/dime_logo_gold.png"
							style="height: 7em"
						/>
					</div>
				</div>
				<!-- Content -->
				<div style="height: 68vh">
					<div
						style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              height: 82%;
            "
					>
						<form style="width: 100%">
							<div class="mb-3">
								<div
									class="w-100 py-2"
									style="
                    border: none;
                    border: 2px solid #ffab00;
                    border-radius: 30px;
                  "
									@click="traverse('presets')"
								>
									<div class="row">
										<div
											class="col col-3"
											style="display: flex; justify-content: center"
										>
											<img
												src="@/assets/icons/nav_icons/presets_white.png"
												style="height: 2.6em; margin-left: 1.2em"
											/>
										</div>
										<div class="col">
											<span
												class="
                          text-uppercase text-white
                          fw-bold
                          h3
                          d-block
                          pt-2
                        "
												>preset temps</span
											>
										</div>
									</div>
								</div>
							</div>
							<!-- <div class="mb-3">
               <div
                 class="w-100 py-2"
                 style="
                   border: none;
                   border: 2px solid #ffab00;
                   border-radius: 30px;
                 "
                 @click="traverse('mystats')"
               >
                 <div class="row">
                   <div
                     class="col col-3"
                     style="display: flex; justify-content: center"
                   >
                     <img
                       src="@/assets/ui/my_stats.png"
                       style="height: 2.6em; margin-left: 1.2em"
                     />
                   </div>
                   <div class="col">
                     <span
                       class="
                         text-uppercase text-white
                         fw-bold
                         h3
                         d-block
                         pt-2
                       "
                       >my stats</span
                     >
                   </div>
                 </div>
               </div>
             </div> -->
							<div class="mb-3">
								<div
									class="w-100 py-2"
									style="
                    border: none;
                    border: 2px solid #ffab00;
                    border-radius: 30px;
                  "
									@click="otaHandle"
								>
									<div class="row">
										<div
											class="col col-3"
											style="display: flex; justify-content: center"
										>
											<img
												src="@/assets/ui/update_device.png"
												style="height: 2.6em; margin-left: 1.2em"
											/>
										</div>
										<div class="col">
											<span
												class="
                          text-uppercase text-white
                          fw-bold
                          h3
                          d-block
                          pt-2
                        "
												>Update Device</span
											>
										</div>
									</div>
								</div>
							</div>
							<!-- <div class="mb-3">
                <div
                  class="w-100 py-2"
                  style="
                    border: none;
                    border: 2px solid #ffab00;
                    border-radius: 30px;
                  "
                  @click="traverse('')"
                >
                  <div class="row">
                    <div
                      class="col col-3"
                      style="display: flex; justify-content: center"
                    >
                      <img
                        src="@/assets/ui/order_now.png"
                        style="height: 2.6em; margin-left: 1em"
                      />
                    </div>
                    <div class="col">
                      <span
                        class="
                          text-uppercase text-white
                          fw-bold
                          h3
                          d-block
                          pt-2
                        "
                        >order now!</span
                      >
                    </div>
                  </div>
                </div>
              </div> -->
							<!-- <div class="mb-3">
                <div
                  class="w-100 py-2"
                  style="
                  border: none;
                  border: 2px solid #ffab00;
                  border-radius: 30px;
                "
                >
                  <div class="row">
                    <div class="col col-3">
                      <img
                        src="@/assets/icons/nav_icons/acc_white.png"
                        style="height: 2.2em; margin-left: 2em;"
                      />
                    </div>
                    <div class="col">
                      <span
                        class="text-uppercase text-white fw-bold h3 d-block pt-2"
                        >my records</span
                      >
                    </div>
                  </div>
                </div>
              </div> -->
						</form>
					</div>
					<div
						style="
              display: flex;
              align-items: center;
              justify-content: center;
              padding-bottom: 4vh;
            "
					>
						<img
							style="height: 3.5em"
							src="@/assets/logos/one_logo_black.png"
						/>
					</div>
				</div>
				<Footer />
			</v-container>
		</template>
		<template v-else> Loading...</template>

		<van-popup v-model="isShowUpgradeTips" round opened="onPopupOpened">
			<UpgradeTipsDialog
				:ota-info="response.otaInfo"
				v-on:onCloseDialog="onCloseDialog"
			/>
		</van-popup>
	</div>
</template>

<script>
import bluetoothRepository from '@/bluetooth/BluetoothRepository'
import device from '../api/device'

import Presets from '@/assets/icons/nav_icons/presets_white.png'

// Components
import SettingsGear from '@/components/Global/SettingsGear.vue'
import Footer from '@/components/Global/Footer.vue'

import { mapGetters } from 'vuex'
import UpgradeTipsDialog from '@/components/dialog/UpgradeTipsDialog'
import { Toast } from 'vant'

export default {
	name: 'login',
	components: {
		SettingsGear,
		Footer,
		UpgradeTipsDialog,
	},
	data() {
		return {
			loading: {
				overall: true,
			},
			response: {
				pages: [
					{
						text: 'presets',
						value: 'presets',
						img: Presets,
					},
				],
				otaInfo: null,
			},
			payload: {},
			isShowUpgradeTips: false,
		}
	},
	created() {
		this.setup()
	},
	methods: {
		/* API Calls */
		// GET
		// POST
		/* Main */
		async setup() {
			await this.setLoadingState(true)
			// Methods
			await this.setLoadingState(false)
		},
		traverse(action) {
			switch (action) {
				case 'presets':
					if (!bluetoothRepository.isConnected() || !this.isInsert) {
						this.$showTips({
							msg:
								'Please connect your device and attachment to see presets',
							duration: 3000,
						})
						return
					}
					this.$router.push({
						name: 'presets',
					})
					break
				case 'mystats':
					this.$router.push({
						name: 'myStats',
					})
					break
			}
		},
		/* Misc */
		async setLoadingState(loadingState) {
			this.loading.overall = loadingState
		},
		otaHandle() {
			if (!bluetoothRepository.isConnected()) {
				this.$showTips({
					msg: 'Please Connect Device',
					duration: 3000,
				})
				// setTimeout(() => {
				//   this.$router.replace({ name: "pair_main" });
				// }, 3200);
				// Toast.fail("Please connect device.");
				// this.$router.replace({ name: "pair_main" });
				return
			}
			this.showLoading('Checking version')
			// Toast.loading("Checking version");

			// this.showLoading("Checking version");

			let hwAndFw = this.$store.state.bluetooth.deviceVersion.split('&')
			if (hwAndFw.length !== 2) return
			let hw = parseInt(hwAndFw[0].replace('HW', ''))
			let fw = parseInt(hwAndFw[1].replace('FW', ''))
			device
				.ota(hwAndFw[0])
				.then((otaInfo) => {
					// this.response.otaInfo  = otaInfo
					// this.isShowUpgradeTips = true
					// Toast.clear()
					if (parseInt(otaInfo.versionNo.replace('FW', '')) > fw) {
						// otaInfo.file = "http://192.168.254.151:8038/static/files/47.zip"
						this.response.otaInfo = otaInfo
						this.isShowUpgradeTips = true
						Toast.clear()
						this.hideLoading()
					} else {
						this.$showTips({
							msg: 'The current version is up to date',
							duration: 1500,
							showType: 'info',
						})
						// Toast.success({
						//   message: "The current version is up to date",
						//   duration: 1500,
						// });
					}
				})
				.catch((err) => {
					Toast.clear()
					this.hideLoading()
				})
		},
		onCloseDialog() {
			this.isShowUpgradeTips = false
		},
	},
	computed: {
		...mapGetters(['getMainColor', 'getAccentColor',
			'isInsert']),
	},
}
</script>

<style></style>
