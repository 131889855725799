<template>
  <div class="upgrade-tips">
    <img
        src="@/assets/ui/upgrade_tips.png"
        style="height: 40vw; width: 40vw;margin: 8vh 0"
    />
    <p>New Version Found</p>

    <div class="btn-container">
      <div class="next-time" @click="nextTime">No, next time</div>
      <div class="upgrade" @click="upgradeDevice">Upgrade</div>
    </div>

  </div>
</template>

<script>
  import bluetoothRepository from "@/bluetooth/BluetoothRepository"

  export default {
    name   : "UpgradeTipsDialog",
    props  : {
      otaInfo: {
        type   : Object,
        default: () => {
          return {}
        }
      }
    },
    methods: {
      upgradeDevice() {
        bluetoothRepository.otaDevice(this.otaInfo)
        this.$emit("onCloseDialog")
      },
      nextTime() {
        this.$emit("onCloseDialog")
      }
    }
  }
</script>

<style lang="less" scoped>
  .upgrade-tips {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #F6F6F6;
    width: 256px;
    background-color: #333333;

    .btn-container {
      width: 100%;
      display: flex;
      flex-direction: row;

      .next-time {
        flex: 1;
        font-size: 13px;
        padding: 10px;
        text-align: center;
        background-color: #222222;
        color: #999999;
      }

      .upgrade {
        flex: 1;
        padding: 10px;
        font-size: 13px;
        text-align: center;
        background-color: #F6F6F6;
        color: #000000;
      }
    }
  }

</style>